import React, { useEffect, useState } from "react";
import HomeBanner from "./HomeBanner";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import HomeItems from "./HomeItems";
import HomeCategory from "./HomeCategory/HomeCategory";
import { useLocation } from "react-router-dom";
import useScrollTop from "../../Hooks/useScrollTop";
import { useTranslation } from "react-i18next";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avatar from "../../Assets/logo/Bharat.jpg";
import BharatAds from "../../GoogleAdSense/BharatAds";

const Home = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const { pathName } = useLocation();
  useScrollTop(pathName);
  const [bannerItems, setBannerItems] = useState([]);

  // const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  // const itemsUrl = `${process.env.REACT_APP_ENDPOINT}/home/loc/${coordinates?.lat}/${coordinates?.lng}`;
  // const { data: homeItems } = useQuery([`items`], () =>
  //   axios.get(itemsUrl).then((data) => {
  //     return data?.data;
  //   })
  // );
  const urlItems = `${process.env.REACT_APP_BACKEND_URL}/items?&lang=${lang}`;
  const { data: items, isLoading: isBannerLoading } = useQuery([`items`, lang], () =>
    axios.get(urlItems).then((data) => {
      // console.log(data);
      return data.data;
    })
  );

  // Fetch data and update selectedCategoryItems when items are available
  useEffect(() => {
    if (!isBannerLoading && items?.length) {
      // console.log("items", items.filter((item) => item?.bannerImg));
      const bannerImgItem = items?.filter((item) => item?.bannerImg);
      setBannerItems(bannerImgItem);
    }
  }, [isBannerLoading, items]);

  const { data: newlyAddedItems, isLoading: newlyAddedItemsLoading } = useQuery(
    {
      queryKey: ["newlyAddedItems"],
      queryFn: async () => {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/items/newly-added-items`
        );
        return res.data;
      },
    }
  );

  const { data: popularItems, isLoading: popularItemsLoading } = useQuery({
    queryKey: ["popularItems"],
    queryFn: async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/items/popular-items`
      );
      return res.data;
    },
  });

  const { data: todayDeals, isLoading: dealItemsLoading } = useQuery({
    queryKey: ["todayDeals"],
    queryFn: async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/items/todayDeals`
      );
      return res.data;
    },
  });

  const todaysDealsMessage = t("Currently there are no items with deals");
  const newlyAddedMessage = t("Currently there are no items added newly");
  const popularMessage = t("Currently there are no popular items");

  return (
    <div className="App min-h-screen space-y-10">

      {/* <BharatAds></BharatAds> */}
      <HomeCategory></HomeCategory>

      <HomeBanner items={bannerItems} loading={isBannerLoading} />

      <HomeItems
        name={t("Todays Deal")}
        items={todayDeals}
        message={todaysDealsMessage}
        loading={dealItemsLoading}
      />
      <HomeItems
        name={t("Newly Added")}
        items={newlyAddedItems}
        message={newlyAddedMessage}
        loading={newlyAddedItemsLoading}
      />
      <HomeItems
        name={t("Popular Items")}
        items={popularItems}
        message={popularMessage}
        loading={popularItemsLoading}
      />
      {/* <HomeItems
        name={"Nearby Items"}
        items={nearbyItems}
        message={nearbyMessage}
      /> */}
      <div className="text-black">
        <FloatingWhatsApp
          avatar={avatar}
          accountName="Bharat Calendar Bhakthi Shop"
          phoneNumber="+918139975010"
          statusMessage="Typically replies within 1 hour"
          chatMessage="How can we help you?"
          messageDelay={1}
        ></FloatingWhatsApp>
      </div>
    </div>
  );
};

export default Home;
