import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const ItemForm = ({ mode, setMode, categories, getItems }) => {

    const [formData, setFormData] = useState({
        asin: '',
        title: '',
        link: '',
        description: '',
        category: '',
        images: [],
        price: '',
        discountedPrice: '',
    })
    const [asin, setAsin] = useState('')
    const [loading, setLoading] = useState(false)
    const queryUrl = `${process.env.REACT_APP_BACKEND_URL}/affiliate/affiliatedItems`

    const renderAddForm = () => {

        const queryASIN = async (e) => {
            e.preventDefault()
            try {
                setLoading(true)
                const res = await axios.get(`${queryUrl}/${asin}`)

                if (res.status === 201) {
                    toast('Item already exists, you can edit it instead.')
                    fetchSignelItem(res.data._id)
                } else {

                    const discountedPrice = res.data[0]?.Offers?.Listings[0]?.Price?.Savings?.DisplayAmount.split('₹')[1].split(' ')[0].replace(/,/g, "") || ''
                    const price = res.data[0]?.Offers?.Listings[0]?.Price?.DisplayAmount.split('₹')[1].replace(/,/g, "") || ''
                    const discountedPriceDiff = '₹' + (parseFloat(price) + parseFloat(discountedPrice)).toLocaleString('en-IN');

                    setFormData({
                        asin: res.data[0].ASIN,
                        link: res.data[0].DetailPageURL,
                        title: res.data[0].ItemInfo.Title.DisplayValue,
                        images: res.data[0].Images.Primary.Large.URL || res.data[0].Images.Primary.Medium.URL || res.data[0].Images.Primary.Small.URL,
                        price: discountedPriceDiff || '0',
                        discountedPrice: res.data[0]?.Offers?.Listings[0]?.Price?.DisplayAmount.split('.')[0] || '0',
                    })
                }
                setLoading(false)

            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }

        const handleSubmit = async (e) => {
            e.preventDefault()
            if (!formData.category || !formData.title || !formData.price || !formData.description || !formData.link) {
                return toast.error('All fields are required')
            }

            try {
                setLoading(true)
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/affiliate/addItem`, formData)
                if (res.status === 200) {
                    toast.success('Item added successfully')
                    setFormData({})
                    setAsin('')
                    setLoading(false)
                    setMode({ mode: '', id: '' })
                    getItems()
                }
            } catch (err) {
                console.log(err)
                toast.error(err.response.data.message)
                setLoading(false)
            }
        }

        return (
            <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(30,30,30,0.5)] backdrop-blur-md z-50 overflow-auto'>
                <form className='absolute top-0 flex flex-col justify-center items-center gap-5 p-4 px-10 '>
                    <div className='rounded-xl flex flex-col justify-center gap-10 items-center p-4 px-10 dark:bg-gray-800 bg-slate-100'>
                        <h2 className='text-2xl dark:text-white'>Add Item</h2>
                        <div className='flex flex-col justify-between gap-5'>
                            <div className='flex w-full justify-between items-center gap-5'>
                                <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='ASIN' value={asin} onChange={(e) => setAsin(e.target.value)} />
                                <button className='btn bg-blue-500 text-white' onClick={(e) => queryASIN(e)} disabled={loading}> Get ASIN data </button>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between gap-5'>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Title</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Affiliate Link</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Link' value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between gap-5'>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white '>Category</label>
                                    <select className='dark:bg-gray-800 text-xl border rounded-full p-1 dark:text-white' onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                                        <option value=''>Select Category</option>
                                        {categories.map((category) => (
                                            <option key={category._id} value={category._id}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Price</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Price' value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Discounted Price</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Discounted Price' value={formData.discountedPrice} onChange={(e) => setFormData({ ...formData, discountedPrice: e.target.value })} />
                                </div>
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <textarea className='p-2 rounded-2xl h-[320px] max-h-[500px] bg-transparent border dark:border-white border-gray-500' placeholder='Description' value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                            </div>
                            <label className='dark:text-white'>Images</label>
                            {formData?.images !== '' && formData.images !== undefined && formData?.images.length > 0 ?
                                <img src={formData.images} alt='item' className='max-w-[200px] max-h-[200px]' />
                                : <p>No images</p>}
                        </div>
                        <div className='flex gap-5 items-center'>
                            <button className='btn bg-blue-500 text-white' onClick={(e) => handleSubmit(e)} disabled={loading}>
                                Add Item
                            </button>
                            <button className='btn bg-blue-500 text-white' onClick={() => setMode({ mode: '', id: '' })} disabled={loading}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const renderEditForm = () => {

        const handleSubmit = async (e) => {
            e.preventDefault()

            if (!formData.category || !formData.title || !formData.price || !formData.description || !formData.link) {
                return toast.error('All fields are required')
            }

            try {
                setLoading(true)
                const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/affiliate/updateItem/${mode.id}`, formData)
                if (res.status === 200) {
                    toast.success('Item updated successfully')
                    setFormData({})
                    setLoading(false)
                    setMode({ mode: '', id: '' })
                    getItems()
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }

        return (
            <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(30,30,30,0.5)] backdrop-blur-md z-50 overflow-auto'>
                <form className='absolute top-0 flex flex-col justify-center items-center gap-5 p-4 px-10 '>
                    <div className='rounded-xl flex flex-col justify-center gap-10 items-center p-4 px-10 dark:bg-gray-800 bg-slate-100'>
                        <h2 className='text-2xl dark:text-white'>Edit Item</h2>
                        <div className='flex flex-col justify-between gap-5'>
                            <div className='flex flex-col md:flex-row justify-between gap-5'>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Title</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Affiliate Link</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Link' value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between gap-5'>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Category</label>
                                    <select className='dark:bg-gray-800 text-xl border rounded-full p-1 dark:text-white' onChange={(e) => setFormData({ ...formData, category: e.target.value })} value={formData.category}>
                                        <option value=''>Select Category</option>
                                        {categories.map((category) => (
                                            <option key={category._id} value={category._id}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Price</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Price' value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-5 w-full'>
                                    <label className='dark:text-white'>Discounted Price</label>
                                    <input className='p-2 rounded-2xl bg-transparent border dark:border-white border-gray-500' type='text' placeholder='Discounted Price' value={formData.discountedPrice} onChange={(e) => setFormData({ ...formData, discountedPrice: e.target.value })} />
                                </div>
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <textarea className='p-2 rounded-2xl h-[210px] max-h-[500px] bg-transparent border dark:border-white border-gray-500' placeholder='Description' value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                            </div>
                            <label className='dark:text-white'>Images</label>
                            {formData?.images !== '' && formData.images !== undefined ?
                                <img src={formData.images} alt='item' className='max-w-[200px] max-h-[200px]' />
                                : <p>No images</p>}
                        </div>
                        <div className='flex gap-5 items-center'>
                            <button className='btn bg-blue-500 text-white' onClick={(e) => handleSubmit(e)} disabled={loading}>
                                Save Item
                            </button>
                            <button className='btn bg-blue-500 text-white' onClick={() => setMode({ mode: '', id: '' })} disabled={loading}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const fetchSignelItem = async (id) => {
        try {
            if (id) mode = { mode: 'edit', id: id }

            const url = `${process.env.REACT_APP_BACKEND_URL}/affiliate/singleItem/${mode.id}`

            await axios.get(url).then((data) => {
                // console.log(data.data)
                setFormData({
                    asin: data.data.asin,
                    title: data.data.title,
                    link: data.data.link,
                    description: data.data.description,
                    images: data.data.images,
                    price: data.data.price,
                    discountedPrice: data.data.discountedPrice,
                    category: data.data.category
                })
                setMode({ mode: 'edit', id: id })
            }).catch((err) => {
                console.log(err)
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (mode.id && mode.mode === 'edit') {
            fetchSignelItem()
        }
    }, [mode.id, mode])

    function renderBasedOnMode() {
        switch (mode.mode) {
            case 'add':
                return renderAddForm()
            case 'edit':
                return renderEditForm()
            default:
                return null
        }
    }

    return (renderBasedOnMode())
}

export default ItemForm