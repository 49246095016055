import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../Pages/Shared/Loader';
import { Link } from 'react-router-dom';

const AllItemsList = () => {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: '' })
    const [loading, setLoading] = useState(false)
    const [dataToRender, setDataToRender] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('All')
    const itemsPerPage = 10;

    const url = `${process.env.REACT_APP_BACKEND_URL}/items?lang=en`;
    const url2 = `${process.env.REACT_APP_BACKEND_URL}/category`;

    const handleDelete = async () => {
        setLoading(true)
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/items/${deleteModal.id}`)
        setLoading(false)
        setDeleteModal({ show: false, id: '' })
    }

    const { data: items, isLoading } = useQuery([`items`, handleDelete], () =>
        axios.get(url).then((data) => {
            return data.data;
        })
    );

    const { data: categories } = useQuery([`categories`], () =>
        axios.get(url2).then((data) => {
            return data.data;
        }));


    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    useEffect(() => {
        if (selectedCategory === 'All') {
            setDataToRender(items?.slice(startIndex, endIndex));
            return;
        }
        const filteredItems = items?.filter((i) => i?.category === selectedCategory)?.slice(startIndex, endIndex);
        setDataToRender(filteredItems);
    }, [currentPage, items, selectedCategory, itemsPerPage]);

    if (isLoading) return <Loading />
    return (
        <div className='md:p-10 p-2'>
            <div className='flex justify-between w-full mb-3'>
                <h1 className='font-bold text-3xl'>Shop items : {items?.length}</h1>
                <div className='flex gap-5'>
                    <button className='btn btn-warning bg-orange-500 text-white' disabled={true} onClick={() => navigate('')}>Add Category</button>
                    <button className='btn btn-warning bg-orange-500 text-white' onClick={() => navigate('addItem')}>Add an Item</button>
                </div>
            </div>
            <div className="flex gap-5">
                <label className='dark:text-white text-xl'>Filter by category</label>
                <select className='dark:bg-secondary text-xl dark:text-white' onChange={(e) => setSelectedCategory(e.target.value)}>
                    <option value='All'>All</option>
                    {categories?.map((category) => (
                        <option className='dark:bg-secondary dark:text-white' key={category._id} value={category?.name}>{category?.name}</option>
                    ))}
                </select>
            </div>
            <div className="overflow-x-auto mt-8 pr-4">
                <table className='table mt-5 w-full'>
                    <thead>
                        <tr className='text-center dark:bg-secondary'>
                            <th className='dark:bg-secondary z-10'>#</th>
                            <th className='dark:bg-secondary'>Image</th>
                            <th className='dark:bg-secondary'>Name</th>
                            <th className='dark:bg-secondary'>Category</th>
                            <th className='dark:bg-secondary'>Quantity</th>
                            <th className='dark:bg-secondary'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataToRender && dataToRender.length > 0 ? dataToRender?.map((item, index) => (
                            <tr key={startIndex + index + 1} className='text-center dark:bg-secondary'>
                                <td className='dark:bg-secondary'>{startIndex + index + 1}</td>
                                <td className='dark:bg-secondary flex items-center justify-center w-full'>
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + item.imageGallery[0]} alt={item.itemName} className='w-32 h-32' />
                                </td>
                                <td className='dark:bg-secondary'>{item.itemName.length > 20 ? item.itemName.slice(0, 20) + '...' : item.itemName}</td>
                                <td className='dark:bg-secondary'>{item.category}</td>
                                <td className='dark:bg-secondary'>
                                    <div className='flex flex-col items-center justify-center gap-3'>
                                        <p>{item.quantity}</p>
                                        <p>{item.quantity > 0 ? 'In Stock' : 'Out of Stock'}</p>
                                    </div>
                                </td>
                                <td className='dark:bg-secondary'>
                                    <div className='flex justify-evenly'>
                                        <button className='btn btn-warning bg-green-500 text-white' onClick={() => navigate(`editItem/${item._id}`)}>Edit</button>
                                        <button className='btn btn-warning bg-red-500 text-white' onClick={() => setDeleteModal({ show: true, id: item._id })}>Delete</button>
                                    </div>
                                    {deleteModal && deleteModal.show && deleteModal.id === item._id &&
                                        <div className='fixed w-full z-50 h-full top-0 left-0 flex justify-center items-center backdrop-blur-sm'>
                                            <div className='flex flex-col bg-gray-100 shadow-xl dark:bg-slate-800 p-5 rounded-md'>
                                                <h1 className='font-bold text-3xl'>Are you sure you want to delete this?</h1>
                                                <div className='flex justify-end gap-5 mt-5'>
                                                    <button
                                                        className='btn btn-warning bg-red-500 text-white'
                                                        onClick={() => setDeleteModal({ show: false, id: '' })}
                                                        disabled={loading}
                                                    >No</button>
                                                    <button
                                                        className='btn btn-success bg-green-500 text-white'
                                                        onClick={() => handleDelete(deleteModal.id)}
                                                        disabled={loading}
                                                    >Yes</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )) : <tr className='text-center dark:bg-secondary'> <td colSpan='6' className='dark:bg-secondary'>No items found</td> </tr>}
                    </tbody>
                </table>
                <div className="flex justify-end items-center gap-1 mt-5">
                    <p className="join-item text-black dark:text-white">Showing {startIndex + 1} - {endIndex} of {items?.length} items -</p>
                    <p className="join-item text-black dark:text-white">on page {currentPage} of {Math.ceil(items?.length / itemsPerPage)}</p>
                </div>
                <div className='flex justify-end gap-5 mt-5'>
                    <button
                        className='btn btn-warning bg-orange-500 text-white'
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >Previous</button>
                    <button
                        className='btn btn-warning bg-green-500 text-white'
                        onClick={() => setCurrentPage((prev) => (prev + 1))}
                        disabled={dataToRender?.length < itemsPerPage}
                    >Next</button>
                </div>
            </div>
        </div >
    )
}

export default AllItemsList