import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { toast } from "react-toastify";
import { auth } from "../../firebase.init";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollTop from "../../Hooks/useScrollTop";
import { useTranslation } from "react-i18next";

const OtpLogin = () => {
  const {t} = useTranslation();
  const [OTP, setOTP] = useState("");
  const [phone, setPhone] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { pathName } = useLocation();
  useScrollTop(pathName);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/profile";

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const onCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  };

  const onSignup = () => {
    setLoading(true);
    onCaptchaVerify();

    const appVerifier = window?.recaptchaVerifier;

    const formatPhone = "+" + phone;

    signInWithPhoneNumber(auth, formatPhone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP Sent Successfully");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onOTPVerify = () => {
    setLoading(true);
    window?.confirmationResult
      ?.confirm(OTP)
      .then(async (res) => {
        console.log(res);
        setUser(res?.user);
        console.log(res?.user?.uid);
        localStorage.setItem("user-id", res?.user?.uid);
        localStorage.setItem("user-mobile", res?.user?.phoneNumber);
        setLoading(false);
        navigate(from, { replace: true });
        toast.success("User has been successfully Logged In.");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Please provide a valid OTP");
      });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (showOTP) {
      onOTPVerify();
    } else if (phone.trim() === "+91" || phone.trim() === "") {
      
      toast.error("Please provide a valid mobile number");
    } else {
      onSignup();
    }
  };
  return (
    <div className="min-h-screen">
      <h1 className="uppercase text-center text-xl mt-10 font-bold">
        {t('Login / Register with OTP')}
      </h1>
      <form onSubmit={handleFormSubmit}>
        {" "}
        {/* Added form element and onSubmit handler */}
        <div id="recaptcha-container"></div>
        {showOTP ? (
          <div className="mt-5">
            <h1 className="text-center font-semibold mb-5">{t('Enter the OTP')}</h1>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              style={{ width: "300px", margin: "auto" }}
              inputStyles={{
                textAlign: "center",
                borderRadius: "6px",
                margin: "auto",
                backgroundColor: "orange",
                color: "white",
                fontWeight: "bold",
              }}
              className="dark:text-black"
            ></OTPInput>
          </div>
        ) : (
          <div className="mt-5">
            <h1 className="text-center font-semibold mb-5">
              {t('Verify Your Mobile Number')}
            </h1>
            <div className="text-center">
              <PhoneInput
                country={"in"}
                value={phone}
                onChange={setPhone}
                style={{ width: "300px", margin: "auto" }}
                className="text-black"
              ></PhoneInput>
            </div>
          </div>
        )}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg-[orange] capitalize btn btn-sm btn-warning w-[300px] gap-1 flex items-center justify-center text-white rounded-lg"
          >
            {loading && (
              <CgSpinner size={20} className="animate-spin"></CgSpinner>
            )}
            <span>{showOTP ? t('Verify OTP') : t('Send OTP')}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default OtpLogin;
