import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const CategoryForm = ({ mode, setMode, getCategories }) => {
    const [formData, setFormData] = useState({
        name: '',
        logo: ''
    })
    const [existingImage, setExistingImage] = useState()
    const [loading, setLoading] = useState(false)

    const url = `${process.env.REACT_APP_BACKEND_URL}/category/amazon`

    useEffect(() => {
        const getSingleCategory = async () => {
            try {
                const response = await axios.get(`${url}/${mode.id}`)
                if (response.status === 200) {
                    setFormData({ name: response.data.name, logo: '' })
                    setExistingImage(response.data.logo)
                }
            } catch (error) {
                console.log(error.response.data.message)
            }
        }

        if (mode.mode === 'editCategory') {
            getSingleCategory()
        }
    }, [mode.mode, mode.id])

    const renderAddCategory = () => {
        const handleAddCategory = async (e) => {
            e.preventDefault()

            if (!formData.name || formData.logo === '') return toast.error('Please fill all fields')

            try {
                setLoading(true)
                const form = new FormData()
                form.append('name', formData.name)
                form.append('logo', formData.logo)

                const response = await axios.post(url, form)
                if (response.status === 200) {
                    toast.success('Category added successfully')
                    setMode('')
                    setLoading(false)
                    getCategories()
                }
            } catch (error) {
                console.log(error.response.data.message)
                toast.error(error.response.data.message)
                setLoading(false)
            }
        }

        return (
            <form className='flex flex-col gap-5 w-full h-full'>
                <label htmlFor='name'>Category Name</label>
                <input
                    type='text'
                    name='name'
                    placeholder='Category Name'
                    className='bg-transparent border p-2 rounded-xl'
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })} value={formData.name} />
                <label htmlFor='logo' className='btn btn-info bg-blue-500 text-white'>Add Category Logo</label>
                <input id='logo' type='file' className='hidden' name='logo' onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
                {formData.logo && <img src={URL.createObjectURL(formData.logo)} alt='category logo' className='w-40 h-40' />}
                <button className='btn btn-warning bg-orange-500 text-white' disabled={loading} onClick={(e) => handleAddCategory(e)}>Add Category</button>
            </form>
        )
    }

    const renderEditCategory = () => {

        const handleEditCategory = async (e) => {
            e.preventDefault()

            if (!formData.name) return toast.error('Please fill all fields')

            try {
                setLoading(true)
                const form = new FormData()
                form.append('name', formData.name)
                form.append('logo', formData.logo)

                const response = await axios.put(`${url}/${mode.id}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (response.status === 200) {
                    toast.success('Category edited successfully')
                    setMode('')
                    setLoading(false)
                    getCategories()
                }
            } catch (error) {
                console.log(error.response.data.message)
                toast.error(error.response.data.message)
                setLoading(false)
            }
        }

        return (
            <div className='flex flex-col gap-5 w-full h-full '>
                <label htmlFor='name'>Category Name</label>
                <input
                    type='text'
                    name='name'
                    placeholder='Category Name'
                    className='bg-transparent border p-2 rounded-xl'
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })} value={formData.name} />
                <div className='flex justify-between items-center'>
                    <div className='flex flex-col gap-5'>
                        <label htmlFor='logo'>Current Category Logo</label>
                        {existingImage && <img src={process.env.REACT_APP_BACKEND_URL + '/' + existingImage} alt='category logo' className='w-40 h-40' />}
                    </div>
                    {formData.logo &&
                        <div className='flex flex-col gap-5'>
                            <label htmlFor='logo'>New Category Logo</label>
                            {formData.logo && <img src={URL.createObjectURL(formData.logo)} alt='category logo' className='w-40 h-40' />}
                        </div>
                    }
                </div>
                <label htmlFor='logo' className='btn btn-info bg-blue-500 text-white'>Change Category Logo</label>
                <input id='logo' type='file' className='hidden' name='logo' onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
                <button className='btn btn-warning bg-orange-500 text-white' disabled={loading} onClick={(e) => handleEditCategory(e)}>Edit Category</button>
            </div>
        )
    }

    const renderBasedOnMode = () => {
        switch (mode.mode) {
            case 'addCategory':
                return renderAddCategory()
            case 'editCategory':
                return renderEditCategory()
            default:
                return renderAddCategory()
        }
    }

    return (
        <div className='flex w-full z-50 h-screen justify-center items-center fixed top-0 left-0 bg-black bg-opacity-20 backdrop-blur-sm'>
            <div className='flex flex-col gap-5 w-1/3 dark:bg-gray-800 bg-slate-100 p-5 rounded-xl'>
                <div className='flex w-full justify-between items-center'>
                    <h1 className='font-bold text-3xl'>{mode === 'addCategory' ? 'Add Category' : 'Edit Category'}</h1>
                    <button className='btn btn-warning bg-red-500 text-white' onClick={() => setMode('')}>Close</button>
                </div>
                {renderBasedOnMode()}
            </div>
        </div>
    )
}

export default CategoryForm